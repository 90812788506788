<script lang="ts" setup>
// SEE: https://bulma.io/documentation/elements/button/
export type Size = 'is-small' | 'is-normal' | 'is-medium' | 'is-large'
type NativeType = 'button' | 'submit' | 'reset'
type Tag = 'button' | 'a' | 'router-link'
type Type =
  | 'is-white'
  | 'is-light'
  | 'is-dark'
  | 'is-black'
  | 'is-text'
  | 'is-ghost'
  | 'is-primary'
  | 'is-link'
  | 'is-danger'

const props = withDefaults(
  defineProps<{
    size?: Size
    type?: Type
    rounded?: boolean
    loading?: boolean
    outlined?: boolean
    expanded?: boolean
    inverted?: boolean
    focused?: boolean
    active?: boolean
    hovered?: boolean
    selected?: boolean
    disabled?: boolean
    nativeType?: NativeType
    tag?: Tag
    icon?: string
  }>(),
  {
    size: 'is-normal' as Size,
    rounded: false,
    loading: false,
    outlined: false,
    expanded: false,
    inverted: false,
    focused: false,
    active: false,
    hovered: false,
    selected: false,
    nativeType: 'button',
    tag: 'button',
  },
)

defineOptions({
  inheritAttrs: false,
})

const slots = useSlots()

const iconClass = computed(() => {
  if (!props.icon) return

  // NOTE: iconify-icon 形式のアイコン指定でも問題ないようにしている
  return ['tw-iconify', `tw-${props.icon.replace(':', '--')}`]
})
</script>

<template>
  <Component
    :is="tag"
    class="button"
    v-bind="$attrs"
    :type="nativeType"
    :disabled="disabled || undefined"
    :class="[
      size,
      type,
      {
        'is-rounded': rounded,
        'is-loading': loading,
        'is-outlined': outlined,
        'is-fullwidth': expanded,
        'is-inverted': inverted,
        'is-focused': focused,
        'is-active': active,
        'is-hovered': hovered,
        'is-selected': selected,
      },
    ]"
  >
    <span v-if="icon" class="icon">
      <span :class="iconClass" />
    </span>
    <span v-if="slots.default"><slot /></span>
  </Component>
</template>
